<template>
  <div class="home">
    <!-- 内容 -->
    <div class="el-row card-position">
      <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-12">
        <div class="left">
          <div class="tip">国际最优秀的面板_华人第一外贸社交下单平台</div>
          <h2 class="service">社交媒体营销一站式服务</h2>
          <p class="text">
            企鹅刷粉是国际上最优秀的社交网络服务面板之一。我们始终致力于提供最优品质的instagram
            facebook twitter youtube
            telegram等服务,我们保证如实的描述服务质量,保证所有的服务每天都会进行测试,保证服务的实际效果和描述一致。
          </p>
          <p class="text">
            我们拥有世界上独一无二的监控系统对每一个服务进行实时统计以便客户挑选使用。每个种类我们都按照质量来提供不同价格的服务来供您选择,有市面上最便宜的粉丝和点赞,也有市面上最高质量的粉丝和点赞。
          </p>
          <p class="text">
            我们非常自豪的以最便宜的价格提供最优质的服务。如果在市场上您找到了比我们更便宜的服务,请在客服系统告诉我们,我们一定会击败他。企鹅刷粉
            是您终极的社交网络推广解决方案。
          </p>
          <!-- 邮箱 -->
          <el-form :inline="true" class="demo-form-inline">
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col  style="color: #fff;margin-bottom: 20px; font-size: 1.6rem;">
                <el-checkbox v-model="checked" ></el-checkbox>
                <span style="margin-left: 0.7rem;">别再买贵的了!购买便宜优质的服务</span>
              </el-col>
            </el-row>
            <el-form-item>
              <el-input
                placeholder="留下您的邮箱"
                style="width: 24rem"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary">开始体验</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-12">
        <div class="el-row el-col-xs-12 el-col-sm-12 el-col-md-12">
          <div class="box">
            <img class="logo" src="@/assets/home/2.png" alt="" />
            <div class="title">我们是领导者</div>
            <div class="tip">您可以从我们的价格中了解</div>
          </div>
        </div>
        <div class="el-row el-col-xs-12 el-col-sm-12 el-col-md-12">
          <div class="box">
            <img class="logo" src="@/assets/home/1.png" alt="" />
            <div class="title">极其便宜</div>
            <div class="tip">您会惊讶于我们所有的价格都如此之低。</div>
          </div>
        </div>
        <div class="el-row el-col-xs-12 el-col-sm-12 el-col-md-12">
          <div class="box">
            <img class="logo" src="@/assets/home/3.png" alt="" />
            <div class="title">付款方法</div>
            <div class="tip">从我们提供的多种付款方式中进行选择。</div>
          </div>
        </div>
        <div class="el-row el-col-xs-12 el-col-sm-12 el-col-md-12">
          <div class="box">
            <img class="logo" src="@/assets/home/4.png" alt="" />
            <div class="title">交货非常快</div>
            <div class="tip">我们的面板的订单交货时间非常短。</div>
          </div>
        </div>
      </div>
    </div>
    <LoginDislog />
    <RegisterDislog />
  </div>
</template>
<script>
import LoginDislog from "@/components/LoginDislog";
import RegisterDislog from "@/components/RegisterDislog/index.vue";
export default {
  data() {
    return {
      checked: false,
    };
  },
  components: {
    LoginDislog,
    RegisterDislog
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding: 8rem 4rem 0;
  height: 100%;

  .left {
    .tip {
      display: inline-flex;
      background: #eea23e;
      border-radius: 12.8rem;
      font-size: 14px;
      color: #ffffff;
      padding: 0.6rem 1rem;
    }
    .service {
      font-weight: bold;
      font-size: 3.5rem;
      color: #ffffff;
      margin: 1.5rem 0;
    }
    .text {
      font-weight: 400;
      font-size: 2rem;
      color: #b2d5ff;
      margin-bottom: 2rem;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    border-radius: 1rem;
    border: 1px solid #e5e5e7;
    padding: 4rem;
    width: 98%;
    height: 24.3rem;
    margin-top: 2%;
    .logo {
      width: 5.6rem;
      height: 5.6rem;
    }
    .title {
      font-size: 2.5rem;
      color: #000000;
      font-weight: bold;

      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-family: Inter, Inter;
    }
    .tip {
      font-weight: 500;
      font-size: 1.5rem;
      color: #858c95;
    }
  }
}
@media screen and (max-width: 768px) {
 .home{
  margin-top: 2rem;
 }
}
</style>
