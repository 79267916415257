<template>
  <el-container class="homecontainer">
    <el-container>
      <el-container>
        <el-main class="s-main">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!--            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
            <!--            <el-breadcrumb-item v-text="this.$router.currentRoute.name"></el-breadcrumb-item>-->
            <div class="div1">
              <h1>
                你好王工，欢迎老板来发财！
              </h1>
              <div>
                Welcome back, we're happy to have you here!
              </div>
            </div>
            <div class="div2">
              <el-row :gutter="10">
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="background: #0A77FF;">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">已完成订单</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">消费金额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">账户余额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>

              </el-row>
            </div>
            <div class="title2">
              <img src="@/assets/user/8.png" alt=""> <span> 关注频道，获取最快上粉</span>
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-input placeholder="请输入内容" v-model="selectValue" class="input-with-select">
                  <el-select v-model="ruleForm.select" slot="prepend" placeholder="全部分类" label="全部分类">
                    <el-option :label="item.name" :value="item.id" v-for="item in categories" :key="item.id"></el-option>
                  </el-select>
                  <el-button slot="append" icon="el-icon-search" @click="submitForm('ruleForm')"></el-button>
              </el-input>
            </el-form>
            <div class="tip">
              <div style="margin-top: 15px;">
                <div class="title-tip">以下单价为USDT，非人民币</div>
                <el-divider></el-divider>
                <el-table
                    :data="tableData"
                    style="width: 100%">
                  <el-table-column
                      type="index"
                      label="id"
                      width="50">
                  </el-table-column>
                  <el-table-column label="服务名称" align="left">
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="价格" width="60">
                    <template slot-scope="scope">
                      {{ scope.row.price }}
                    </template>
                  </el-table-column>
                  <el-table-column  width="40">
                    <template slot-scope="scope">
                      <i class="el-icon-arrow-right" @click="$router.push('/user/index')"></i>
                    </template>
                  </el-table-column>
                </el-table>
                <div style="text-align: center;margin-top: 30px;" v-if="false">

                  <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="30">
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-breadcrumb>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

  </el-container>
</template>

<script>
import DataList from "@/data/api/category.json"

export default {
  name: "index",

  data() {
    return {
      ruleForm: {
        select:'',

      },
      rules: {
        select: [
          { required: true, message: '数量不能为空', trigger: 'blur' },
        ],
      },
      /*tableData: [{
        num: '+ 6,320.53',
        address: 'Linkedin领英公司粉丝100关注'
      }, {
        num: '+ 6,320.53',
        address: 'Linkedin领英公司粉丝100关注'
      }, {
        num: '+ 6,320.53',
        address: 'Linkedin领英公司粉丝100关注'
      }, {
        num: '+ 6,320.53',
        address: 'Linkedin领英公司粉丝100关注'
      }],*/
      categories: DataList.data
    }
  },
  computed:{
    tableData(){
      const selected = this.selected
      if (selected){
        return selected.product
      }
      return []
    },
    selected(){
      const list = this.categories
      const selected = list.find(item=>item.id === this.ruleForm.select)
      return selected
    },
    selectValue(){
      const selected = this.selected
      if (selected){
        return selected.name
      }
      return ''
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleOpen() {
    },
    handleChange(value) {
      console.log(value);
    },
    handleClose() {
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('SUCCSE submit!!');
        } else {
          console.log('error submit!!');
          this.$message.error('请求失败');
          return false;
        }
      });
    },

  },

}
</script>

<style scoped lang="scss">
.s-main {
  padding: 32px;
  background: linear-gradient(173deg, #00439D 0%, #FFFFFF 50%, #FFFFFF 100%);
}

.homecontainer {
  height: calc(100vh - 60px);
  width: 100%;
  .div1 {
    h1 {
      font-family: Noto Sans, Noto Sans;
      font-weight: bold;
      font-size: 3rem;
      color: #F9F9F9;
      line-height: 38px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    div {
      height: 24px;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #F9F9F9;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  .div2 {
    .grid-content {
      //width: 100%;
      text-align: center;
      height: 28rem;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #E5E5E7;
      font-family: Inter, Inter;

      .img1 {
        width: 48px;
        height: 48px;
        background: #EEA23E;
        line-height: 66px;
        border-radius: 6px;
        margin: 30px auto 20px;

        img {
          width: 28px;
          height: 28px;
        }
      }

      .title {
        height: 50px;
        font-weight: bold;
        font-size: 2rem;
        color: #323539;
        line-height: 38px;
        font-style: normal;
        text-align: center;
      }

      .smaile {
        height: 30px;
        font-weight: 500;
        font-size: 14px;
        color: #858C95;
      }

      .number {
        height: 20px;
        font-weight: normal;
        font-size: 14px;
        color: #E33B32;
        line-height: 20px;
      }
    }
  }
  .title2 {
    height: 80px;
    background: #ECF4FC;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #0E69E2;
    color: #000000;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin: 20px 0;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 5px;
    }
    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 2rem;
      color: #323539;
      line-height: 30px;

    }
  }
  .tip {
    min-height:  436px;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #E5E5E7;
    padding: 10px 20px;
    margin-top: 30px;
    .title-tip {
      height: 24px;
      font-family: Inter, Inter;
      font-weight: bold;
      font-size: 1.8rem;
      color: #323539;
      line-height: 24px;
    }
    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #323539;
      line-height: 22px;
    }
  }
  .demo-ruleForm {
    margin-top: 50px;
  }

}
@media all and (max-width: 768px) {
  .s-main {
    padding: 2rem;
  }
  .homecontainer {

    .title2 {
      display: none;
    }
    .title3 {
      background: #D3E7FF;
      border-radius: 6px 6px 6px 6px;
      color: #000000;
      display: flex;
      margin: 20px 0;
      padding: 10px;
      span {
        width: 8px;
        height: 8px;
        background: #0A77FF;
        display: inline-block;
        border-radius: 8px;
        margin-right: 5px;
      }
    }
  }
}


</style>

