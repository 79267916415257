<template>
  <div class="loginPage">
    <el-dialog :visible.sync="loginDislogShow" @close="close" width="50%">
      <div slot="title" class="title">登录</div>
      <el-form ref="loginForm" :rules="rules" :model="loginForm">
        <el-form-item label="用户名" prop="name">
          <el-input
            v-model="loginForm.name"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            autocomplete="off"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
       <!-- <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="9">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
          </el-col>
          <el-col :span="9">
            <div class="yourPassword">忘记密码？</div>
          </el-col>
        </el-row>-->
        <el-row class="btns">
          <el-button class="mt10" @click="close">取消</el-button>
          <el-button type="primary" class="mt10" :loading="loading" @click="submitForm">登录</el-button>
        </el-row>

        <el-row type="flex" class="row-bg mt10">
          <div>没有账号？</div>
          <div class="rg" @click="register">注册</div>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import http from "@/common/http";

export default {
  data() {
    return {
      loginForm: {
        name: "",
        password: "",
      },
      checked: false,
      loading: false,
      rules:{
        name: {required:true,message:'请输入用户名',trigger:"blur"},
        password: {required:true,message:'请输入密码',trigger:"blur"}
      }
    };
  },
  computed: {
    ...mapState({
      loginDislogShow: (state) => state.user.loginDislogShow,
    }),
  },
  methods: {
    ...mapActions("user", ["setLoginDislogShow",'setRegisterDialogShow']), // 确保路径正确
    register(){
      this.close()
      this.setRegisterDialogShow(true)
    },
    close() {
      this.setLoginDislogShow(false);
    },
    handleClose(done) {
      // 如果需要关闭前进行一些操作，比如表单验证，可以在这里处理
      done();
    },
    submitForm() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          http({
            method: "post",
            url: "/web/user/login",
            data: this.loginForm
          }).then(res=>{
            this.$message.success('登录成功')
            const token = res.signcode
            localStorage.setItem("token",token)
            this.$router.push({
              path:'/user/index',
            });
          }).catch(()=>{

          }).finally(()=>{
            this.loading = false
          })
        }
      })
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.loginPage {
  .btns{
    text-align: center;
  }
  .title {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
  }
  .yourPassword {
    text-align: right;
    color: #0045a2;
    font-weight: 500;
  }
  .rg {
    color: #0045a2;
    font-weight: 500;
  }
}
</style>
