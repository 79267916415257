import Vue from 'vue';
import VueRouter from 'vue-router';
import About from '@/components/About.vue';
import layoutA from '@/layout/layoutA.vue';
import layoutB from '@/layout/layoutB.vue';
import LayoutC from '@/layout/layoutC.vue';

import Home from '@/views/home';
import adminHome from '@/views/admin/home';
import UserIndex from '@/views/user/index';
import UserCz from '@/views/user/cz';
import UserTk from '@/views/user/tk';
import UserOrder from '@/views/user/order';
import UserShopping from '@/views/user/shopping.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: layoutA,
    children: [
      {
        path: '',
        component: Home
      },
    ]
  },
  {
    path: '/user',
    component: LayoutC,
    children: [
      {
        path: 'index',
        component: UserIndex,
        exact: true,
      },
      {
        path: 'cz',
        component: UserCz,
        exact: true,
      },
      {
        path: 'tk',
        component: UserTk,
        exact: true,
      },
      {
        path: 'order',
        component: UserOrder,
        exact: true,
      },
      {
        path: 'shopping',
        component: UserShopping,
        exact: true,
      },
      {
        path: 'api',
        component: ()=>import('@/views/user/api.vue'),
        exact: true,
      },
    ]
  },
  {
    path: '/admin',
    component: layoutB,
    children: [
      {
        path: 'index',
        component: adminHome
      },
    ]
  },

];

const router = new VueRouter({
  mode: 'hash',
  routes
});



export default router;
