<template>
    <div class="home">
      asdasdasd
    </div>
  </template>
  <script>
  export default {
    components: {
    },
  };
  </script>

