<template>
  <el-container class="homecontainer">
    <el-container>
      <el-container>
        <el-main class="s-main">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!--            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
            <!--            <el-breadcrumb-item v-text="this.$router.currentRoute.name"></el-breadcrumb-item>-->
            <div class="div1">
              <h1>
                你好王工，欢迎老板来发财！
              </h1>
              <div>
                Welcome back, we're happy to have you here!
              </div>
            </div>
            <div class="div2">
              <el-row :gutter="10">
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="background: #0A77FF;">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">已完成订单</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">消费金额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">账户余额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>

              </el-row>
            </div>
            <div class="tip">
              <div class="title2">
                <span></span>由于第三方充值风控限制,目前只能USDT充值,请针对相应钱包进行转账充值,充值成功自动到账。
              </div>
              <p>1.输入数量 点击支付跳转到支付页面。</p>
              <p>2.选择您要支付的货币类型如(USDT-TRC20)</p>
              <p>3.选择相应的钱包付款</p>
              <p>4.虚拟自动充值10-20分钟自动到账,如果您不会自动充值,请联系人工客户充值</p>
              <p>5.充值异常,请联系客服</p>
              <el-divider></el-divider>
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <div style="padding-bottom: 20px;">充值数量</div>
                <el-input-number v-model="ruleForm.num" @change="handleChange" :min="1"
                                 label="充值数量"></el-input-number>
                <el-button type="warning" style="width:100%;margin-top: 20px;" icon="el-icon-edit-outline"
                           @click="toRecharge" :disabled="!domain?.bagdomain">立即充值
                </el-button>
              </el-form>
            </div>
            <div class="title3">
              <span></span>由于第三方充值风控限制,目前只能USDT充值,请针对相应钱包进行转账充值,充值成功自动到账。
            </div>
          </el-breadcrumb>
          <qrcode-dialog v-if="showCode" :domain="domain.paydomain" :money="ruleForm.num" :show="showCode"
                         @close="showCode = false"/>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import DataList from "@/data/tsconfig.json"
import http from "@/common/http";
import MobileDetect from "mobile-detect";
import QrcodeDialog from "@/views/user/qrcodeDialog.vue";

console.log(`DataList`, DataList)
export default {
  name: "index",
  components: {QrcodeDialog},

  data() {
    return {
      ruleForm: {
        num: 1, //数量
      },
      rules: {
        num: [
          {required: true, message: '数量不能为空', trigger: 'blur'},
        ],
      },
      domain: {},
      showCode: false
    }
  },
  created() {
    this.getDomain()
  },
  methods: {
    detectDevice() {
      const userAgent = navigator.userAgent || window.opera;
      const md = new MobileDetect(userAgent);

      if (md.mobile()) {
        return 'mobile';
      } else if (md.tablet()) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    },
    handleClick(row) {
      console.log(row);
    },
    handleOpen() {
    },
    handleChange(value) {
      console.log(value);
    },
    handleClose() {
    },
    getDomain() {
      http({
        url: "/web/user/paydomain",
        method: "post",
      }).then(res => {
        if (res.code === 200) {
          this.domain = res.data
        }
      })
    },
    toRecharge(num) {
      const deviceType = this.detectDevice();
      if (deviceType === 'desktop') {
        this.showCode = true
        return
      }
      if (this.domain?.bagdomain) {
        window.location.href = `${this.domain.bagdomain}#/?money=${this.ruleForm.num}`;
      } else {
        //没有钱包
        this.$message.error('获取数据失败')
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message({
            message: '充值成功',
            type: 'success'
          });
        } else {
          console.log('error submit!!');
          this.$message.error('请求失败');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }

  },

}
</script>

<style scoped lang="scss">
.s-main {
  padding: 32px;
  background: linear-gradient(173deg, #00439D 0%, #FFFFFF 50%, #FFFFFF 100%);
}

.homecontainer {
  height: calc(100vh - 60px);
  width: 100%;

  .div1 {
    h1 {
      font-family: Noto Sans, Noto Sans;
      font-weight: bold;
      font-size: 3rem;
      color: #F9F9F9;
      line-height: 38px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    div {
      height: 24px;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #F9F9F9;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }

  .div2 {
    .grid-content {
      //width: 100%;
      text-align: center;
      height: 28rem;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #E5E5E7;
      font-family: Inter, Inter;

      .img1 {
        width: 48px;
        height: 48px;
        background: #EEA23E;
        line-height: 66px;
        border-radius: 6px;
        margin: 30px auto 20px;

        img {
          width: 28px;
          height: 28px;
        }
      }

      .title {
        height: 50px;
        font-weight: bold;
        font-size: 2rem;
        color: #323539;
        line-height: 38px;
        font-style: normal;
        text-align: center;
      }

      .smaile {
        height: 30px;
        font-weight: 500;
        font-size: 14px;
        color: #858C95;
      }

      .number {
        height: 20px;
        font-weight: normal;
        font-size: 14px;
        color: #E33B32;
        line-height: 20px;
      }
    }
  }

  .title2 {
    padding: 10px;
    background: #D3E7FF;
    border-radius: 6px 6px 6px 6px;
    color: #000000;
    display: flex;
    align-items: center;
    margin: 20px 0;

    span {
      width: 8px;
      height: 8px;
      background: #0A77FF;
      display: inline-block;
      border-radius: 8px;
      margin-right: 5px;
    }
  }

  .tip {
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #E5E5E7;
    padding: 10px 20px;
    margin-top: 30px;

    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #323539;
      line-height: 22px;
    }
  }

  .demo-ruleForm {
    margin-top: 50px;
  }

  .title3 {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .s-main {
    padding: 2rem;
  }
  .homecontainer {

    .title2 {
      display: none;
    }

    .title3 {
      background: #D3E7FF;
      border-radius: 6px 6px 6px 6px;
      color: #000000;
      display: flex;
      margin: 20px 0;
      padding: 10px;

      span {
        width: 8px;
        height: 8px;
        background: #0A77FF;
        display: inline-block;
        border-radius: 8px;
        margin-right: 5px;
      }
    }
  }
}


</style>

