
const state = {
  loginDislogShow: false,
  registerDialogShow: false,
}

const mutations = {
  SET_LOGINDISLOGSHOW: (state, loginDislogShow) => {
    state.loginDislogShow = loginDislogShow
  },
  SET_REGISTERDISLOGSHOW: (state, registerDialogShow) => {
    state.registerDialogShow = registerDialogShow
  },
}
const actions = {
  setLoginDislogShow({ commit }, data) {
    commit('SET_LOGINDISLOGSHOW', data);
  },
  setRegisterDialogShow({ commit }, data) {
    commit('SET_REGISTERDISLOGSHOW', data);
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
