<template>
  <div class="header">
    <div class="el-row">
      <div class="el-col el-col-24 el-col-xs-12 el-col-sm-20 el-col-md-20 nav">
        <div class="title">企鹅刷粉</div>
        <div class="titleBox flexR">
          <div class="navText">首页</div>
          <div class="navText" @click="toShopping">商城</div>
          <div class="navText">关于</div>
        </div>
      </div>
      <div class="el-col el-col-24 el-col-xs-12 el-col-sm-4 el-col-md-4 userHandl">
        <div class="login" @click="register">注册</div>
        <div class="register" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      checked: false,
    };
  },

  methods: {
    ...mapActions("user", ["setLoginDislogShow","setRegisterDialogShow"]), // 确保路径正确
    toShopping(){
      const token = localStorage.getItem("token");
      if (!token){
        this.$message.info('请登录账号')
        setTimeout(()=>{
          this.setLoginDislogShow(true)
        }, 1000)
      }
    },
    register() {
      this.setRegisterDialogShow(true)
    },
    login() {
      this.setLoginDislogShow(true);
    },
    handleClose(done) {
      // 如果需要关闭前进行一些操作，比如表单验证，可以在这里处理
      done();
    },
    submitForm() {
      // 这里处理登录逻辑，比如发送请求到后端
      console.log("登录信息:", this.loginForm);
      // 登录成功后关闭弹窗
      this.dialogVisible = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.header {
  padding: 2.4rem 4rem;
  margin-bottom: 4.8rem;

  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      font-size: 2.4rem;
      color: #ffffff;
      margin-right: 6.4rem;
    }
    .navText {
      font-weight: 600;
      font-size: 1.5rem;
      color: #f9f9f9;
      margin-right: 1.2rem;
    }
  }
  .userHandl {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .login {
      font-size: 1.5rem;
      color: #f9f9f9;
    }
    .register {
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
      border-radius: 0.6rem;
      border: 1px solid #e5e5e7;
      font-size: 1.5rem;
      color: #323539;
      padding: 1.2rem 2rem;
      margin-left: 2.4rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .titleBox {
    display: none !important;
  }
}
</style>
