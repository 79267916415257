<script>
import QRCodeStyling from 'qr-code-styling'
export default {
  name: "qrcode",
  props:{
    show:{
      type: Boolean,
      default: false
    },
    money: {
      type: [Number, String],
      default: 0
    },
    domain:{
      type: String,
      default: ''
    }
  },
  data(){
    return {
      links :[
        {
          path: '/trc',
          text: 'TRC',
          image: require('@/assets/index/TRC.png'),
        },
        {
          path: '/erc',
          text:'ERC',
          image: require('@/assets/index/ERC.png'),
        },
        {
          path: '/erc',
          text: 'BSC',
          image: require('@/assets/index/BSC.png'),
        },
      ],
      tabs: '0'
    }
  },
  mounted() {
    const qrCode = new QRCodeStyling({
      width: 360,
      height: 360,
      data: '11',
      // image: 'https://www.example.com/logo.png', // 可选：在二维码中心添加图像
      dotsOptions: {

        color: '#1867c0',
        type: 'rounded'
      },
      backgroundOptions: {
        color: '#e9ebee'
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 20
      }
    })
    console.log(qrCode)
    this.$watch('tabs', (newVal) => {
      const {path,image} = this.links[newVal]
      const data = `${this.domain}${path}?money=${this.money}`
      qrCode.update({
        data,
        image
      });
      this.$nextTick(()=>{
        qrCode.append(this.$refs.qrcode);
      })
    },{immediate: true})
  }
}
</script>

<template>
<el-dialog :visible="show" title="请选择对应的链进行支付" width="400px" @close="$emit('close')">
  <el-tabs v-model="tabs">
    <el-tab-pane :label="item.text" :name="index+''" v-for="(item,index) in links"></el-tab-pane>
  </el-tabs>
  <div ref="qrcode" class="d-flex justify-center"></div>
  <div class="tips">为防止黑U假U混币，请使用钱包扫码支付请切记转账金额要与订单金额一致！</div>
</el-dialog>
</template>

<style scoped lang="scss">
.tips{
  color: #E93323;
  font-size: 14px;
  margin-top: 24px;
}
</style>