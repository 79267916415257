import axios from "axios";
import msg from "@/common/code";
import {Message} from "element-ui";

const http = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 10000,
})

http.interceptors.response.use(res => {
    console.log(res.data);
    const data = res.data;
    if (data && data.code && data.code !== 200) {
        Message.error(msg[data.code]);
        return Promise.reject(msg[data.code]);
    }
    return data
})
export default http