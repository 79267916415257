<template>
    <div>
      <h1>About Page</h1>
      <p>Welcome to the About Page!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'About'
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  