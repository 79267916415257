<template>
  <el-container class="homecontainer">
    <el-container>
      <el-container>
        <el-main class="s-main">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!--            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
            <!--            <el-breadcrumb-item v-text="this.$router.currentRoute.name"></el-breadcrumb-item>-->
            <div class="div1">
              <h1>
                你好王工，欢迎老板来发财！
              </h1>
              <div>
                Welcome back, we're happy to have you here!
              </div>
            </div>
            <div class="div2">
              <el-row :gutter="10">
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="background: #0A77FF;">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">已完成订单</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">消费金额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">账户余额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>

              </el-row>
            </div>
            <div class="title2">
              <img src="@/assets/user/8.png" alt=""> <span> 关注频道，获取最快上粉</span>
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-input placeholder="请输入内容" v-model="ruleForm.select" class="input-with-select">
                <!-- <el-select v-model="ruleForm.select" slot="prepend" placeholder="全部分类" label="全部分类">
                   <el-option label="餐厅名" value="1"></el-option>
                   <el-option label="订单号" value="2"></el-option>
                   <el-option label="用户电话" value="3"></el-option>
                 </el-select>-->
                <el-button slot="append" icon="el-icon-search" @click="submitForm('ruleForm')"></el-button>
              </el-input>
            </el-form>
            <div class="tip">
              <div style="margin-top: 15px;">
                <!--<div class="title-tip">以下单价为USDT，非人民币</div>-->
                <!--<el-divider></el-divider>-->
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="所有" name="first"></el-tab-pane>
                  <el-tab-pane label="等待" name="second"></el-tab-pane>
                  <el-tab-pane label="进行中" name="third"></el-tab-pane>
                  <el-tab-pane label="完成" name="fourth"></el-tab-pane>
                  <el-tab-pane label="部分(已退余额)" name="fifth"></el-tab-pane>
                  <el-tab-pane label="处理" name="sixth"></el-tab-pane>
                  <el-tab-pane label="取消" name="seventh"></el-tab-pane>
                </el-tabs>
                <el-table :data="tableData" style="width: 100%">
                  <el-table-column
                      type="index"
                      label="id"
                      width="50">
                  </el-table-column>
                  <!--<el-table-column label="编号" prop="id" width="50"/>-->
                  <el-table-column label="日期" prop="date" width="120"/>
                  <el-table-column label="服务名称" width="200">
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="价格">
                    <template slot-scope="scope">
                      {{ scope.row.price }}
                    </template>
                  </el-table-column>
                  <el-table-column label="数量" prop="num" width="60"/>
                  <el-table-column label="剩余" prop="ramain" width="60"/>
                  <!-- <el-table-column  width="50">
                     <template slot-scope="scope">
                       <i class="el-icon-arrow-right"></i>
                     </template>
                   </el-table-column>-->
                </el-table>
                <!-- <div style="text-align: center;margin-top: 30px;">

                   <el-pagination
                       background
                       layout="prev, pager, next"
                       :total="30">
                   </el-pagination>
                 </div>-->
              </div>
            </div>
          </el-breadcrumb>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

  </el-container>
</template>

<script>
import DataList from "@/data/tsconfig.json"
import Mock from 'mockjs'
import dayjs from "dayjs";

console.log(`DataList`, DataList)
export default {
  name: "index",

  data() {
    return {
      ruleForm: {
        select: '',

      },
      activeName: 'first',
      rules: {
        select: [
          {required: true, message: '数量不能为空', trigger: 'blur'},
        ],
      },
      tableData: [],
    }
  },
  created() {
    this.getArray()
  },
  watch:{
    activeName(newVal, oldVal){
      this.getArray()
    }
  },
  methods: {
    getArray() {
      let result = [];
      for (let i = 0; i < 10; i++) {
        const item = Mock.Random.pick(DataList.data)
        // item.id = Mock.Random.id();
        item.date = this.getRandomDate()
        item.price = Mock.Random.float(0,2).toFixed(2)
        item.num = Mock.Random.integer(1,999)
        item.ramain = Mock.Random.integer(1,999)
        result.push(item)
      }
      // this.tableData = result;
    },
    // 生成指定范围内的随机日期
    getRandomDate() {
      // 获取 start 和 end 的时间戳
      const startDate = dayjs().subtract(6, 'months').valueOf();
      const endDate = new Date().getTime();

      // 随机生成 start 到 end 范围内的时间戳
      const randomTimestamp = Mock.Random.integer(startDate, endDate);

      // 返回格式化的日期字符串
      return dayjs(randomTimestamp).format('YYYY-MM-DD');
    },
    handleClick(row) {
      console.log(row);
    },
    handleOpen() {
    },
    handleChange(value) {
      console.log(value);
    },
    handleClose() {
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('SUCCSE submit!!');
        } else {
          console.log('error submit!!');
          this.$message.error('请求失败');
          return false;
        }
      });
    },

  },

}
</script>

<style scoped lang="scss">
.s-main {
  padding: 32px;
  background: linear-gradient(173deg, #00439D 0%, #FFFFFF 50%, #FFFFFF 100%);
}

.homecontainer {
  height: calc(100vh - 60px);
  width: 100%;

  .div1 {
    h1 {
      font-family: Noto Sans, Noto Sans;
      font-weight: bold;
      font-size: 3rem;
      color: #F9F9F9;
      line-height: 38px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    div {
      height: 24px;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #F9F9F9;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }

  .div2 {
    .grid-content {
      //width: 100%;
      text-align: center;
      height: 28rem;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #E5E5E7;
      font-family: Inter, Inter;

      .img1 {
        width: 48px;
        height: 48px;
        background: #EEA23E;
        line-height: 66px;
        border-radius: 6px;
        margin: 30px auto 20px;

        img {
          width: 28px;
          height: 28px;
        }
      }

      .title {
        height: 50px;
        font-weight: bold;
        font-size: 2rem;
        color: #323539;
        line-height: 38px;
        font-style: normal;
        text-align: center;
      }

      .smaile {
        height: 30px;
        font-weight: 500;
        font-size: 14px;
        color: #858C95;
      }

      .number {
        height: 20px;
        font-weight: normal;
        font-size: 14px;
        color: #E33B32;
        line-height: 20px;
      }
    }
  }

  .title2 {
    height: 80px;
    background: #ECF4FC;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #0E69E2;
    color: #000000;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin: 20px 0;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 5px;
    }

    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 2rem;
      color: #323539;
      line-height: 30px;

    }
  }

  .tip {
    //height: 436px;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #E5E5E7;
    padding: 10px 20px;
    margin-top: 30px;

    .title-tip {
      height: 24px;
      font-family: Inter, Inter;
      font-weight: bold;
      font-size: 1.8rem;
      color: #323539;
      line-height: 24px;
    }

    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #323539;
      line-height: 22px;
    }
  }

  .demo-ruleForm {
    margin-top: 50px;
  }

}

@media all and (max-width: 768px) {
  .s-main {
    padding: 2rem;
  }
  .homecontainer {
    height: calc(100vh - 130px);

    .title2 {
      display: none;
    }

    .title3 {
      background: #D3E7FF;
      border-radius: 6px 6px 6px 6px;
      color: #000000;
      display: flex;
      margin: 20px 0;
      padding: 10px;

      span {
        width: 8px;
        height: 8px;
        background: #0A77FF;
        display: inline-block;
        border-radius: 8px;
        margin-right: 5px;
      }
    }
  }
}


</style>

