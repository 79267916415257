<template>
  <el-container>
    <el-header class="mobile-no"><LayoutHeader></LayoutHeader></el-header>
    <el-container>
      <el-aside width="300px" class="pc-wiv1">
        <LayouAside></LayouAside>
      </el-aside>
      <el-container>
        <el-main><router-view></router-view></el-main>
      </el-container>
    </el-container>
    <el-footer>
      <LayoutFooter></LayoutFooter>
    </el-footer>
  </el-container>
</template>

<script>
import LayoutHeader from "@/components/LayoutHeader/pc";
import LayouAside from "@/components/LayouAside/index";
import LayoutFooter from "@/components/LayoutFooter";
export default {
  name: "App",
  components: { LayoutHeader, LayoutFooter,LayouAside },
};
</script>

<style lang="scss" scoped>
.pc-wiv1 {

}
aside {
  background: #323539;
  margin: 0;
  padding: 0;
}
.layoutA {
  height: 100vh;
}
.el-header{
  padding: 0;
  height: 70px!important;
}
.el-main{
  padding: 0;
}
.el-footer{
  padding: 0;
}
.mobile-no {
  display: none;
}
@media all and (max-width: 768px) {
  .pc-wiv1 {
    display: none;
  }
  .mobile-no {
    display: block;
  }
}
</style>
