<template>
<div  class="pc-heard">
  <div class="pc-title">
    <div style="display: flex;align-items: center;padding-bottom: 10px;">
      <img src="@/assets/mobile/Frame@2x.png" class="head-logo" />
      <span class="mobile-title">企鹅刷粉</span>
    </div>
    <div class="money">￥ 0.00</div>
  </div>
  <el-menu style="background-color: transparent;color: #fff;"
           router
           active-text-color="#fff"
           text-color="#979AA0"
           :default-active="active"
           class="el-menu-vertical-demo"
           @open="handleOpen"
           @close="handleClose">
    <el-menu-item index="1" class="el-color-white" @click="logout">
      <i class="el-icon-switch-button"></i>
      <span slot="title" >退出</span>
    </el-menu-item>
    <el-menu-item :index="item.index" class="el-color-white" v-for="item in menus" :route="{ path: item.path}" :key="item.index">
      <i :class="item.icon"></i>
      <span slot="title">{{ item.text }}</span>
    </el-menu-item>
   <!-- <el-menu-item index="3" class="el-color-white" :route="{ path: '/user/index'}">
      <i class="el-icon-aim"></i>
      <span slot="title">下单</span>
    </el-menu-item>
    <el-menu-item index="4" class="el-color-white" :route="{ path: '/user/order'}">
      <i class="el-icon-time"></i>
      <span slot="title">我的订单</span>
    </el-menu-item>
    <el-menu-item index="5" class="el-color-white" :route="{ path: '/user/cz'}">
      <i class="el-icon-s-data"></i>
      <span slot="title">充值</span>
    </el-menu-item>-->
    <!--           <el-menu-item index="6" class="el-color-white">
                <i class="el-icon-picture-outline-round"></i>
                <span slot="title">加入代理</span>
              </el-menu-item>-->
   <!-- <el-menu-item index="7" class="el-color-white" :route="{ path: '/user/tk'}">
      <i class="el-icon-discount"></i>
      <span slot="title">服务条款</span>
    </el-menu-item>-->

  </el-menu>
</div>
</template>

<script>
import router from "@/router";

export default {
  name: "index",
  data(){
    return {
      menus:[
        {
          path: '/user/shopping',
          text: '商城',
          index:'2',
          icon: 'el-icon-s-shop'
        },
        {
          path: '/user/index',
          text: '下单',
          index: '3',
          icon: 'el-icon-aim'
        },
        {
          path: '/user/order',
          text: '我的订单',
          index: '4',
          icon: 'el-icon-time'
        },
        {
          path: '/user/cz',
          text: '充值',
          index: '5',
          icon: 'el-icon-s-data'
        },
        {
          path: '/user/tk',
          text: '服务条款',
          index: '7',
          icon: 'el-icon-discount'
        },
        {
          path: '/user/api',
          text: 'api接口',
          index: '8',
          icon: 'el-icon-document'
        },
      ],
      active: '2'
    }
  },
  mounted() {
    const path = this.$route.path;
    const matched = this.menus.find(item => item.path === path)
    this.active = matched.index
    router.afterEach(to => {
      console.log('afterEach',to)
      const path = to.path
      const matched = this.menus.find(item => item.path === path)
      this.active = matched.index
    })
  },
  methods: {
    logout(){
      localStorage.clear()
      this.$router.push('/')
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style scoped lang="scss">

  .pc-heard {
    background-color: #323539;padding: 0;height: calc(100vh - 60px) ;
    //display: none;
    .el-menu {
      border-right: 0;
    }
    .pc-title {
      padding: 1rem 2rem;
      .head-logo {
        width: 26px;
        height: 26px;
        margin-left: 10px;
      }
      .mobile-title {
        color: #F9F9F9;
        font-size: 20px;
      }
      .money {
        width: 68px;
        height: 24px;
        line-height: 24px;
        padding: 0 5px;
        background: #EEA23E;
        border-radius: 6px 6px 6px 6px;
        margin: 0 10px;
        font-family: Inter, Inter;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .el-color-white {
      color: #979AA0;
      padding: 0!important;
      text-align: left;
      padding-left: 2rem!important;
    }
    .is-active , .el-color-white:hover {
      background-color:#2B2D2F;
      color: #fff!important;
    }
    .el-menu-vertical-demo {
      height: auto;
    }
    .el-aside {
      background-color: #D3DCE6;
      color: #333;
      text-align: center;
      line-height: 200px;
    }
  }
</style>
