<template>
  <el-container class="homecontainer">
    <el-container>
      <el-container>
        <el-main class="s-main">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!--            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
            <!--            <el-breadcrumb-item v-text="this.$router.currentRoute.name"></el-breadcrumb-item>-->
            <div class="div1">
              <h1>
                你好王工，欢迎老板来发财！
              </h1>
              <div>
                Welcome back, we're happy to have you here!
              </div>
            </div>
            <div class="div2">
              <el-row :gutter="40">
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="background: #0A77FF;">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">已完成订单</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">消费金额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <div class="img1" style="">
                      <img src="@/assets/user/Icon@2x(1).png" alt="">
                    </div>
                    <div class="title">0</div>
                    <div class="smaile">账户余额</div>
                    <div class="number">0%</div>
                  </div>
                </el-col>

              </el-row>
            </div>
            <div class="tip">
              <p>1、请务必阅读每项服务的完整说明。</p>
              <p>2、同一链接禁止在订单状态未显示完成之前再次下单。等待第一个订单完成,然后为同一链接下新订单。</p>
              <p>3、同一个链接同时购买多个拉人上粉项目数量不会叠加且不退款</p>
              <p>4、下单后订单未完成禁止更改链接 更改链接=订单完成</p>
              <p>5、订单如果出现延迟请耐心等待</p>
            </div>
            <div class="div3">
              <el-row :gutter="10">
                <el-col  :span="16" :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                  <div class="grid-content bg-purple">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

                      <el-form-item label="服务类别" prop="region">
                        <el-select v-model="ruleForm.region" placeholder="点击搜索服务类别" style="width: 100%;">
                          <el-option v-for="item in categories" :label="item.name" :value="item.id"></el-option>
                          <!--<el-option label="区域二" value="beijing"></el-option>-->
                        </el-select>
                      </el-form-item>
                      <el-form-item label="服务项目" prop="region">
                        <el-select v-model="ruleForm.product" placeholder="点击搜索服务项目" style="width: 100%;">
                          <el-option v-for="item in product" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="描述" prop="desc">
                        <el-input type="textarea" v-model="ruleForm.desc" placeholder="描述" autosize></el-input>
                      </el-form-item>
                      <el-form-item label="账号/链接" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="填写要刷的链接或账号"></el-input>
                      </el-form-item>

                      <el-form-item label="单价" prop="price">
                        <el-input placeholder="请输入内容" v-model="ruleForm.price">
                          <template slot="append">USDT</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="数量" prop="num">
                        <el-input-number v-model="ruleForm.num" @change="handleChange" :min="1"  label="数量"></el-input-number>
                      </el-form-item>
                      <el-form-item label="总价" prop="allPrice">
                        <el-input placeholder="请输入内容" v-model="ruleForm.allPrice">
                          <template slot="append">USDT</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary"  icon="el-icon-edit-outline" style="width:100%;" @click="submitForm('ruleForm')">立即下单</el-button>
<!--                        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
                      </el-form-item>
                    </el-form>
                  </div>
                </el-col>
                <el-col  :span="8" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                  <div class="grid-content bg-purple">
                    <div class="title1">
                      服务实时更新
                    </div>
                    <div class="title2">
                      <span></span>今日新增服务
                    </div>
                    <div class="ul">
                      <p>Twitter 转推 [大v] 24小时转推+1个超级帐户赞</p>
                      <p> Twitter 转推 [大v] 24小时转推+5个超级帐户赞</p>
                      <p>Twitter 转推 [大v] 24小时转推+10个超级帐户赞</p>
                      <p>Twitter 转推 [大v] 24小时转推+20个超级帐户赞</p>
                      <p>Twitter 转推 [大v] 24小时转推+30个超级帐户赞</p>
                    </div>

                    <div class="title2">
                      <span></span>今日新增僵尸服务
                    </div>
                    <div class="ul">
                      <p>Twitter 转推 [大v] 24小时转推+1个超级帐户赞</p>
                      <p> Twitter 转推 [大v] 24小时转推+5个超级帐户赞</p>
                      <p>Twitter 转推 [大v] 24小时转推+10个超级帐户赞</p>
                      <p>Twitter 转推 [大v] 24小时转推+20个超级帐户赞</p>
                      <p>Twitter 转推 [大v] 24小时转推+30个超级帐户赞</p>
                    </div>

                    <div class="title2">
                      <span></span>今日新增在线僵尸服
                    </div>
                    <div class="ul">
                      <p>电报会员 在线僵尸(一个月)</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-breadcrumb>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

  </el-container>
</template>

<script>
import DataList from "@/data/api/category.json"

export default {
  name: "index",

  data() {
    return {
      ruleForm: {
        price: '', //单价
        allPrice: '', //总价
        num: 1, //数量
        name: '',
        region: '',
        type: [],
        resource: '',
        desc: '',
        product: '' // 项目
      },
      rules: {
        price: [
          { required: true, message: '单价不能为空', trigger: 'blur' },
        ],
        allPrice: [
          { required: true, message: '总价不能为空', trigger: 'blur' },
        ],
        num: [
          { required: true, message: '数量不能为空', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入账号/链接', trigger: 'blur' },
          // { min: 3, message: '', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      categories: DataList.data
    }
  },
  computed:{
    product(){
      const val = this.ruleForm.region
      const selected = this.categories.find(item => item.id === val)
      return selected?.product || []
    }
  },
  watch:{
    'ruleForm.price':function(val){
      const {num} = this.ruleForm
      this.ruleForm.allPrice = (num * val) .toFixed(2)
    },
    'ruleForm.num':function(val){
      const {price} = this.ruleForm
      this.ruleForm.allPrice = (price * val) .toFixed(2)
    },
    'ruleForm.product':function(val){
      const selected = this.product.find(item => item.id === val)
      const price = selected?.price || 0
      this.ruleForm.price = price
    },
    'ruleForm.region':function(val){
      const selected = this.categories.find(item => item.id === val)
      this.ruleForm.product = ''
      this.ruleForm.desc = selected.describe
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleOpen() {
    },
    handleChange(value) {
      console.log(value);
    },
    handleClose() {
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        console.log('submitForm',valid)
        if (valid) {
          this.$message({
            message: '余额不足，请先充值',
            type: 'info'
          });
          this.$router.push('/user/cz')
        } else {
          console.log('error submit!!');
          // this.$message.error('请求失败');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }

  },

}
</script>

<style scoped lang="scss">
.s-main {
  padding: 32px;
  background: linear-gradient(173deg, #00439D 0%, #FFFFFF 50%, #FFFFFF 100%);
}

.homecontainer {
  //height: 763px;
  height: calc(100vh - 60px);
  width: 100%;

  .div1 {
    h1 {
      font-family: Noto Sans, Noto Sans;
      font-weight: bold;
      font-size: 3rem;
      color: #F9F9F9;
      line-height: 38px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    div {
      height: 24px;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #F9F9F9;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }

  .div2 {
    .grid-content {
      //width: 100%;
      text-align: center;
      height: 246px;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #E5E5E7;
      font-family: Inter, Inter;

      .img1 {
        width: 48px;
        height: 48px;
        background: #EEA23E;
        line-height: 66px;
        border-radius: 6px;
        margin: 30px auto 20px;

        img {
          width: 28px;
          height: 28px;
        }
      }

      .title {
        height: 50px;
        font-weight: bold;
        font-size: 3rem;
        color: #323539;
        line-height: 38px;
        font-style: normal;
        text-align: center;
      }

      .smaile {
        height: 30px;
        font-weight: 500;
        font-size: 14px;
        color: #858C95;
      }

      .number {
        height: 20px;
        font-weight: normal;
        font-size: 14px;
        color: #E33B32;
        line-height: 20px;
      }
    }
  }
  .div3 {
    margin-top: 30px;
    .grid-content {
      //height: 840px;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.04);
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #E5E5E7;
      padding: 20px;
      margin-bottom: 20px;
    }
    .title1 {
      line-height: 26px;
      background: #FFFFFF;
      border-bottom: 1px solid #E5E5E7;
      font-family: Inter, Inter;
      font-size: 16px;
      color: #323539;
      margin: -10px -20px 20px;
      padding: 0 20px 10px;

    }
    .title2 {
      width: 155px;
      height: 30px;
      background: #D3E7FF;
      border-radius: 6px 6px 6px 6px;
      color: #000000;
      display: flex;
      align-items: center;
      padding-left: 10px;
      span {
        width: 8px;
        height: 8px;
        background: #0A77FF;
        display: inline-block;
        border-radius: 8px;
        margin-right: 5px;
      }
    }
    .ul {
      margin: 20px 0 10px;
      border-bottom: 1px solid #E5E5E7;
      padding-bottom: 15px;
      p{
        font-family: Inter, Inter;
        font-weight: 500;
        font-size: 15px;
        color: #525D6A;
        line-height: 22px;
      }
    }
  }
  .tip {
    height: 18rem;
    background: #ECF4FC;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #0A77FF;
    padding: 10px 20px;
    margin-top: 30px;

    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #323539;
      line-height: 22px;
    }
  }
}
@media all and (max-width: 768px) {
  .s-main {
    padding: 10px;
    .div2 {
      .el-row {
        padding: 0 10px!important;
        .el-col {
          padding: 0 10px!important;
        }
      }
    }
    .div3 {
      .grid-content {
        height: auto;
        margin-bottom: 20px;
      }
    }
    .tip {
      height: 28rem;
    }
  }
}

</style>

