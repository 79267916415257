<template>
  <el-container class="homecontainer">
    <el-container>
      <el-container>
        <el-main class="s-main">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <div class="div1">
              <h1>服务条款</h1>
              <p>
                注册或使用我们的服务即表示您同意您已阅读并完全理解以下服务条款,对于未阅读以下服务条款的用户,Telead 将不承担任何形式的损失。
              </p>
              <h2>重要规则</h2>
              <p>

                1、您只能以遵循与 Instagram/facebook/twitter/youtube/其他社交媒体网站在其各自的服务条款页面上达成的所有协议的方式使用该网站。 <br/>
                2、企鹅刷粉将仅用于宣传您的Telegram/Instagram/Twitter/Facebook和其他社交资料以进行个人参与并提高您的社交效率。 <br/>
                3、不要同时为相同的链接 使用两个服务。如果您这样做,我们将无法修复它。等待第一个订单完成,然后为同一链接下新订单。 <br/>
                4、在我们的面板中向帐户收费后无法退款。 <br/>
                5、仅使用门票部分以获得支持。 <br/>
                6、开始后无法取消订单。除非你有充分的理由。 <br/>
                7、如果用户在注册后超过3个月没有任何订单/存款,我们将终止帐户,恕不另行通知。 <br/>
                8、企鹅刷粉不保证任何服务的交货时间。我们对何时交付订单提供最佳估计。 <br/>
              </p>
              <h2>隐私政策</h2>
              <p>
                本页向您介绍我们在您使用我们的服务时收集、使用和披露个人数据的政策，以及您与该数据相关的选择。

                我们使用您的数据来提供和改进服务。使用本服务，即表示您同意按照本政策收集和使用信息。除非本隐私政策中另有定义，否则本隐私政策中使用的术语与我们的条款和条件中的含义相同，可从 https://xxxxxxxxxxxxxxxxx/ 访问
              </p>
              <h2>跟踪和 Cookie 数据</h2>
              <p>
                我们使用 cookie 和类似的跟踪技术来跟踪我们服务上的活动并保存某些信息。

                Cookie 是包含少量数据的文件，其中可能包含匿名唯一标识符。Cookie 从网站发送到您的浏览器并存储在您的设备上。还使用的跟踪技术是信标、标签和脚本，用于收集和跟踪信息以及改进和分析我们的服务。
              </p>
              <h2>数据的使用</h2>
              <p>
                企鹅刷粉将收集到的数据用于各种目的 <br/>

                提供和维护服务<br/>
                通知您有关我们服务的更改<br/>
                允许您在选择时参与我们服务的互动功能<br/>
                供客户关怀和支持<br/>
                提供分析或有价值的信息，以便我们改进服务<br/>
                监控服务的使用情况<br/>
                检测、预防和解决技术问题<br/>
              </p>
              <h2>企鹅刷粉能会真诚地披露您的个人数据，认为此类行动对于以下方面是必要的：</h2>
              <p>
                遵守法律义务 <br/>
                保护和捍卫 telead.cc 的权利或财产<br/>
                防止或调查与服务有关的可能的不当行为<br/>
                保护服务使用者或公众的人身财产安全<br/>
              </p>
              <h2>数据安全与隐私政策</h2>
              <p>您数据的安全性对我们很重要，但请记住，没有任何一种通过 Internet 传输的方法或电子存储方法是 100% 安全的。虽然我们努力使用商业上可接受的方式来保护您的个人数据。<br/>

                我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策来通知您任何更改。<br/>

                在更改生效之前，我们将通过电子邮件和/或我们服务上的醒目通知通知您，并更新本隐私政策顶部的“生效日期”。<br/>

                建议您定期查看本隐私政策以了解任何更改。本隐私政策的更改一经发布在此页面上即生效。</p>
            </div>
          </el-breadcrumb>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

  </el-container>
</template>

<script>
import DataList from "@/data/tsconfig.json"

console.log(`DataList`,DataList)
export default {
  name: "index",

  data() {
    return {

    }
  },
  methods: {

  },

}
</script>

<style scoped lang="scss">
.s-main {
  padding: 32px;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .homecontainer{
    height: calc(100vh - 130px);
  }
}
.homecontainer {
  height: calc(100vh - 60px);
  width: 100%;
  .div1 {
    padding: 80px;
    h1 {
      margin: 0 30px 20px;
      font-family: Inter, Inter;
      font-weight: bold;
      font-size: 32px;
      color: #323539;
      line-height: 40px;
      text-align: center;
    }
    h2 {
      font-family: Inter, Inter;
      font-size: 18px;
      color: #323539;
      line-height: 28px;
      padding-top: 30px;
      padding-bottom: 10px;
    }
    p {
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 18px;
      color: #858C95;
      line-height: 26px;
    }
  }

}
@media all and (max-width: 768px) {
  .homecontainer {
    .div1 {
      padding: 0;
    }
  }
}
</style>

