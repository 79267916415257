<template>
  <div class="loginPage">
    <el-dialog :visible.sync="registerDialogShow" @close="close" width="50%">
      <div slot="title" class="title">注册</div>
      <el-form ref="loginForm" :rules="rules" :model="loginForm">
        <el-form-item label="用户名" prop="name">
          <el-input
            v-model="loginForm.name"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="loginForm.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            autocomplete="off"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repassword">
          <el-input
            type="password"
            v-model="loginForm.repassword"
            autocomplete="off"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <!--<el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="9">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
          </el-col>
          <el-col :span="9">
            <div class="yourPassword">忘记密码？</div>
          </el-col>
        </el-row>-->
        <el-row class="btns">
          <el-button class="mt10" @click="setRegisterDialogShow(false)">取消</el-button>
          <el-button :loading="loading" type="primary" class="mt10" @click="submitForm">注册</el-button>
        </el-row>
        <el-row type="flex" class="row-bg mt10">
          <div>已有账号？</div>
          <div class="rg" @click="login">返回登录</div>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import http from "@/common/http";

export default {
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.loginForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        name: "",
        email:"",
        password: "",
        repassword:''
      },
      loading: false,
      checked: false,
      rules:{
        name: [{required:true,message:'请输入用户名',trigger:"blur"},{min:3,message: '请输入至少3个字符',trigger:"blur"}],
        email:{required:true,message:"请输入邮箱",trigger:"blur"},
        password: [{required:true,message:'请输入密码',trigger:"blur"},{min:6,message: '请输入至少6个字符',trigger:"blur"}],
        repassword:{validator:validatePass2,trigger:"blur"}
      }
    };
  },
  computed: {
    ...mapState({
      registerDialogShow: (state) => state.user.registerDialogShow,
    }),
  },
  methods: {
    ...mapActions("user", ["setLoginDislogShow",'setRegisterDialogShow']), // 确保路径正确
    login(){
      this.close();
      this.setLoginDislogShow(true);
    },
    close() {
      this.setRegisterDialogShow(false);
    },
    handleClose(done) {
      // 如果需要关闭前进行一些操作，比如表单验证，可以在这里处理
      done();
    },
    submitForm() {
      const param = {
        ...this.loginForm,
      }
      delete param.repassword
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          http({
            method: "post",
            url: "/web/user/reg",
            data: param
          }).then(res=>{
            this.$message.success('注册成功');
            setTimeout(()=>{
              this.login()
            },500)
          }).catch((err)=>{

          }).finally(()=>{
            this.loading = false
          })
        }
      })
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.loginPage {
  .btns{
    text-align: center;
  }
  .title {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
  }
  .yourPassword {
    text-align: right;
    color: #0045a2;
    font-weight: 500;
  }
  .rg {
    color: #0045a2;
    font-weight: 500;
  }
}
</style>
