<template>
  <el-container class="layoutA">
    <el-header>
      <LayoutHeader></LayoutHeader>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer>
      <LayoutFooter></LayoutFooter>
    </el-footer>
  </el-container>
</template>

<script>
import LayoutHeader from "@/components/LayoutHeader";
import LayoutFooter from "@/components/LayoutFooter";
export default {
  name: "App",
  components: { LayoutHeader, LayoutFooter },
};
</script>

<style lang="scss" scoped>
.layoutA {
  height: 100vh;
  background-color: #0045a2;
}
.el-header{
  padding: 0;
}
.el-main{
  padding: 0;
}
.el-footer{
  padding: 0;
}
</style>
