<template>
  <div class="mobile-div">
    <div class="pc-mobile">
      <img src="@/assets/mobile/Frame@2x.png" class="head-logo"/>
      <span class="mobile-title">企鹅刷粉</span>
      <span class="money">￥ 0.00</span>
      <el-dropdown trigger="click"  @command="handleCommand">
        <span class="el-dropdown-link">
          <img src="@/assets/mobile/Menus@2x.png" class="menus-logo"/>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-switch-button"  command="/">退出</el-dropdown-item>
          <!--<el-dropdown-item icon="el-icon-s-home" command="/">首页</el-dropdown-item>-->
          <el-dropdown-item icon="el-icon-aim" command="/user/index" >下单</el-dropdown-item>
          <el-dropdown-item icon="el-icon-time" command="/user/order" >我的订单</el-dropdown-item>
          <el-dropdown-item icon="el-icon-s-data" command="/user/cz" >充值</el-dropdown-item>
          <el-dropdown-item icon="el-icon-discount" command="/user/tk" >服务条款</el-dropdown-item>
          <el-dropdown-item icon="el-icon-document" command="/user/api" >api接口</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  data() {
    return {

    };
  },

  methods: {
    ...mapActions("user", ["setLoginDislogShow"]), // 确保路径正确
    login() {
      this.setLoginDislogShow(true);
    },
    handleCommand(command) {
      const currentRoute = this.$route.path;
      if (currentRoute === command){
        return;
      }
      switch (command){
        case '/':
          this.$router.push('/');
          return
        case '/user/index':
          this.$router.push('/user/index');
          return;
        case '/user/order':
          this.$router.push('/user/order');
          return;
        case '/user/cz':
          this.$router.push('/user/cz');
          return;
        case '/user/tk':
          this.$router.push('/user/tk');
          return;
        case '/user/api':
          this.$router.push('/user/api');
          return;
      }
    }
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.mobile-div {
  //display: none;

  .pc-mobile {
    background-color: #181818;
    font-family: Noto Sans, Noto Sans;
    font-weight: bold;
    font-size: 20px;
    color: #F9F9F9;
    display: flex;
    align-items: center;
    height: 70px;
    padding: 10px;

    .head-logo {
      width: 24px;
      height: 24px;
      margin-left: 10px;

    }

    .mobile-title {
      flex: 1;
    }

    .money {
      width: 68px;
      height: 24px;
      line-height: 24px;
      padding: 0 5px;
      background: #EEA23E;
      border-radius: 6px 6px 6px 6px;
      margin: 0 10px;
      font-family: Inter, Inter;
      font-weight: 500;
      font-size: 14px;
    }

    .menus-logo {
      width: 30px;
      height: 30px;
    }

    .el-dropdown-link {
      cursor: pointer;
      color: #409EFF;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }

    .demonstration {
      display: block;
      color: #8492a6;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .pc-heard {
    display: none;
  }
  .mobile-div {
    display: block;
  }
  .titleBox {
    display: none !important;
  }
}
</style>
