import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Element from 'element-ui'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
Vue.use(Element)
import store from './store'; // 确保路径正确

console.log('Vuex Store:', store); // 添加调试信息

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)

}).$mount('#app');
