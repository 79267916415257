const msg = {
    10101: '账号错误，至少3字符',
    10103: '密码错误，至少6字符',
    10104: '账号不存在',
    10106: '用户被冻结或被限制登陆',
    40000:'其它错误',
    10201:'账号已存在，不能重复注册',
    10202: '邮箱不已存在，不能重复注册',
    10102: '邮箱错误',
}
export default msg