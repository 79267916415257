<!-- App.vue -->
<template>
    <el-container>
      <el-header v-if="isMobile">
        <el-menu :default-active="activeMenu" class="el-menu-demo" mode="horizontal">
          <el-menu-item index="1">导航一</el-menu-item>
          <el-menu-item index="2">导航二</el-menu-item>
          <el-menu-item index="3">导航三</el-menu-item>
        </el-menu>
      </el-header>
      <el-container>
        <el-aside v-if="!isMobile" width="200px">
          <el-menu :default-active="activeMenu" class="el-menu-vertical-demo">
            <el-menu-item index="1">导航一</el-menu-item>
            <el-menu-item index="2">导航二</el-menu-item>
            <el-menu-item index="3">导航三</el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
            <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeMenu: '1',
        isMobile: false
      };
    },
    mounted() {
      this.checkIsMobile();
      window.addEventListener('resize', this.checkIsMobile);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkIsMobile);
    },
    methods: {
      checkIsMobile() {
        this.isMobile = window.innerWidth <= 768;
      }
    }
  };
  </script>
  
  <style>
  /* 你可以根据需要调整样式 */
  .el-menu-demo {
    line-height: 60px;
  }
  .el-menu-vertical-demo {
    height: 100%;
    border-right: none;
  }
  </style>
  